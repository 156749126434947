
import {defineComponent} from 'vue';
import {initials} from '@/tools/filters';
import {Application} from '@/types/Application';

export default defineComponent({
    name: 'IdentityAppsComponent',
    props: {
        loadingApps: {
            required: true,
            default: false,
            type: Boolean
        },
        error: {
            required: true,
            default: false,
            type: Boolean
        },
        applications: {
            required: true,
            type: Array
        }
    },
    setup ()
    {

        function displayColor (appDetail: Application)
        {
            if (!appDetail.hexColor) return '#A00095';
            if (!appDetail.hexColor.includes('#')) return `#${appDetail.hexColor}`;
            return appDetail.hexColor;
        }

        return {initials, displayColor};
    }
});
