
import {computed, defineComponent, onMounted, reactive, toRefs} from 'vue';
import IdentityAppsComponent from '@/components/admin/IdentityAppsComponent.vue';
import {toast} from 'bulma-toast';
import {useStore} from 'vuex';

interface Context {
    loadingApps: boolean;
    error: boolean;
    allowed: boolean;
}

export default defineComponent({
    name: 'Home',
    components: {IdentityAppsComponent},
    setup ()
    {

        const {dispatch} = useStore();
        const store = useStore();

        const context: Context = reactive({
            loadingApps: false,
            error: false,
            allowed: true,
            tab: 1
        });

        const myApplications = computed(() => store.state.application.myApplications);
        const applications = computed(() => store.state.application.applications);

        async function getApplications ()
        {
            context.loadingApps = true;
            try
            {
                await dispatch('application/getApplications', null, {root: true});
            }
            catch (e)
            {
                if (e.response && (e.response.status === 403 || e.response.status === 401)) context.allowed = false;
                else
                {
                    toast({
                        message: 'Error loading applications',
                        type: 'is-danger',
                        duration: 5000,
                        dismissible: true,
                        pauseOnHover: true,
                        position: 'bottom-right'
                    });
                }
            }
            context.loadingApps = false;
        }

        onMounted(async () =>
        {
            if (myApplications.value.length === 0 || applications.value.length === 0)
            {
                await getApplications();
            }
        });
        return {...toRefs(context), getApplications, myApplications, applications};
    }
});
