export const initials = function (appName: string): string
{
    const arrayName = appName.split(' ');
    if (arrayName.length > 1)
    {
        return (arrayName[0].substring(0, 1) + arrayName[1].substring(0, 1)).toUpperCase();
    }
    return appName.substring(0, 2)
        .toUpperCase();
};

export const yesOrNot = function (field: boolean): string
{
    if (field) return 'Yes';
    else return 'No';
};
